<template>
  <div class="aiz-user-panel">
    
    <div class="card">
      <div class="card-header row gutters-5">
        <div class="text-center text-md-left">
          <h5 class="mb-md-0 h5">{{detailData.Subject}} #{{detailData.ID}}</h5>
          <div class="mt-2">
            <span>{{userInfo.Email}}</span>
            <span class="ml-2">{{detailData.CreatedAt}}</span>
            <span class="badge badge-inline badge-secondary ml-2">{{detailData.Status}}</span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form >
          <div class="form-group">
            <textarea class="form-control mb-4" name="reply" rows="8" v-model="form.content"></textarea>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <div class="input-group" data-toggle="aizuploader" data-type="image" data-multiple="true">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile">{{ $t('xuan-ze-wen-jian') }}</div>
                <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
              </div>
              <previewBar :fileList="fileList" @remove="removeFile"></previewBar>
            </div>
          </div>
          <div class="form-group mb-0 text-right">
            <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('fa-song-hui-fu') }}</button>
          </div>
        </form>
        <div class="pad-top">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0" v-for="(item, i) in messageList" :key="i">
              <div class="media">
                <a class="media-left">
                  <span class="avatar avatar-sm mr-3">
                    <img :src="item.headerUrl || headerUrl">
                  </span>
                </a>
                <div class="media-body">
                  <div class="comment-header">
                    <span class="text-bold h6 text-muted">{{item.IsService == 2 ? userInfo.Email: '商家'}}</span>
                    <p class="text-muted text-sm fs-11">{{item.CreatedAt}}</p>
                  </div>
                </div>
              </div>
              <div>
                <div v-html="item.Content"></div>
                <br>
                <div v-for="(file, index) in item.fileList" :key="index">
                  <a  download="" class="badge badge-lg badge-inline badge-light mb-1" v-if="file">
                    <i class="las la-download text-muted">{{ file.Name }}</i>
                  </a>
                  <br>  
                </div>  
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import previewBar from '@/components/preview'
import {Popup} from 'vant'
import { workOrderReply, workOrderList, fileUpload  } from '@/api/user'
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      form: {
        content: ''
      },
      headerUrl: require('../../assets/imgs/avatar-place.png'),
      fileList: [],
      showPopup: false,
      userMoney: 10,
      messageList: [],
      detailData: {}
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    let data = sessionStorage.getItem('ticketData')
    if (data) {
      this.detailData = JSON.parse(data)
      
    }
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      workOrderList().then(res => {
        let list = res.data.Items.filter(v => {
          return v.ID == this.dataId
        })
        if (list.length > 0) {
          this.detailData = list[0]
        }
        let messageList = this.detailData.WorkOrderReply.map(v => {
          let files = JSON.parse(v.Images)
          let fileList = []
          if (files.length > 0) {
            fileList = files[0].split(',').map(v => {
              return {
                Name: v,
                url: v
              }
            })
          }
          v.fileList = fileList
          return v
        })
        let files = JSON.parse(this.detailData.Images)
        let fileList = []
        if (files.length > 0) {
          fileList = files[0].split(',').map(v => {
            return {
              Name: v,
              url: v
            }
          })
        }
        let defaultMsg = {
          IsService: 2,
          CreatedAt: this.detailData.CreatedAt,
          Content: this.detailData.Content,
          fileList: fileList
        }
        this.messageList = [defaultMsg].concat(messageList)
      })
    },
    addFile() {
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let fileName = file.name
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          this.fileList.push({
            id: res.data,
            url: `/upload/${res.data}`,
            name: fileName
          })
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    removeFile(i) {
      this.fileList.splice(i, 1)
    },
    submit() {
      let form = new FormData()
      form.append('WorkOrderID', this.dataId)
      form.append('Content', this.form.content)
      form.append('Images', this.fileList.map(v => {
        return v.id
      }))
      workOrderReply(form).then(res => {
        if (res.code == 0) {
          this.form.content = ''
          this.fileList = []
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>